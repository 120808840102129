

.navbar {
	height: 80px;

	background: blue;
	color: white;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 2rem;
	font-size: 1.2rem;
}

.nav-logo {
	font-size: 1.5rem;
	text-decoration: none;
	color: rgb(222, 33, 33);
	
}

.nav-links {
	display: grid;
	grid-template-columns: repeat(5, auto);
	grid-gap: 20px;
	list-style: none;
}

.nav-link {
	text-decoration: none;
	color: white;
	transition: 0.3s all;
}

.nav-link:hover {
	color: red;
}

.nav-icon {
	display: none;
	font-size: 2rem;
	cursor: pointer;
}

@media only screen and (max-width: 830px) {
	.navbar {
		position: relative;
	}

	.nav-links {
	
		flex-direction: column;
		position: absolute;
		text-align: center;
	
		top: 80px;
		left: -100%;
		transition: 0.5s all;
	}

	.nav-links.active {
		background: blue;
		left: 0;
	}

	.nav-item {
		padding: 5px 0;
		
	}

	.nav-icon {
		display: flex;
	}

	.hometext5 {
		font-size: 30px;
	  }
  
	  .hometext3 {
		font-size: 25px;
		padding: 20px;
	  }
  
	  .hometext1 {
		font-size: 20px;
		padding: 20px;
	  }
  
	
   
  
   
}
