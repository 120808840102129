
  


.informationbackground{
  background-image: url(/public/Images/LightBlueWallPaper.jpg);
  background-size: 150px;
  width: 1000 px;
  height: 200px;
  border: 2px solid;
  color: pink;
}


.informationtext {
  text-align: center;
  position: relative;
  color: rgb(238, 31, 128);
  font-family:  'Gill Sans', 'Gill Sans MT';
  font-size: 100px;
  background: transparent  ; 
  padding: 90px;
  opacity: 100;
}

.informationtext1 {

  text-align: center;
  position: relative;
  color: rgb(194, 26, 26);
  font-family:  'Gill Sans ', 'Gill Sans MT ';
  font-size: 75px;
  background: transparent  ; 
  padding: 60px;
}

.informationtext2 {

  text-align: left;
  position: relative;
  color: rgb(194, 26, 26);
  font-family:  'Gill Sans ', 'Gill Sans MT ';
  font-size: 32px;
  background: transparent  ; 
  padding: 40px;
} 

.listtext {

  text-align: left;
  position: relative;
  color: white;
  font-family:  'Gill Sans ', 'Gill Sans MT ';
  font-size: 15px;
  background: transparent  ; 
  padding: 5px;
} 
.footer-container {

  margin: -5px; 
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-template-rows:40px 40px;
  grid-gap: 10px;
  list-style: none;
  color: white;
  background-color: #493ffcdc;
  padding: 4rem ;
  align-items: center;
  height:15vh;
  bottom: 10px;
  top: 10px;
}


.list-unstyled{
  margin-left: -5;
  padding-left: 40px;



}



.copyrighttext {
  text-align: center;
  position: relative;
  color: rgb(236, 237, 245);
  font-family:  'Gill Sans', 'Gill Sans MT';
  font-size: 15px;
  background: transparent  ; 
  padding-top: 60px;
  opacity: 100;
}


  .box1{
   
    display: flex;
    align-items: center; 
    padding: 0 2rem;
    border: 15px solid rgb(9, 17, 248);
  }

  .box2{
   
   
    align-items: center; 
    padding: 2rem;
    border: 15px solid green;
  }

  .box3{
   
   
    align-items: center; 
    padding: 0 2rem;
    border: 10px solid green;
  }

  .box4{
   
   
    align-items: center; 
    padding: 0 2rem;
    border: 5px solid green;
  }

  .box5{
   
  
    align-items: center; 
    padding: 0 2rem;
    border: 2px solid green;
  }

  .box6{
    height: 10px;
    width: 10px;
   
    display: center;
    align-items: center; 
    padding: 0 2rem;
    border: 15px solid white;
  }

  .box7{
   
    display: flex;
    align-items: center; 
    padding: 0 2rem;
    border: none;
  }

  .text {
    text-align: center;
    position: relative;
    color: rgb(9, 17, 248);
    font-family:  'Gill Sans', 'Gill Sans MT';
    font-size: 100px;
    background: transparent  ; 
    padding: 90px;
    opacity: 100;
  }

  .hometext3 {

    text-align: center;
    position: relative;
    color: rgb(20, 16, 236);
    font-family:  'Gill Sans ', 'Gill Sans MT ';
    font-size: 30px;
    background: transparent  ; 
    padding: 60px;
  }
  .hometext1 {

    text-align: left;
    position: relative;
    color: rgb(4, 102, 50);
    font-family:  'Gill Sans ', 'Gill Sans MT ';
    font-size: 30px;
    background: transparent  ; 
    padding: 60px;
  }
  .hometext2 {

    text-align: center;
    position: relative;
    color: rgb(236, 16, 152);
    font-family:  'Gill Sans ', 'Gill Sans MT ';
    font-size:45px;
    background: transparent  ; 
    padding: 20px;
  }

  .hometext3 {

    text-align: center;
    position: relative;
    color: rgb(32, 14, 237);
    font-family:  'Gill Sans ', 'Gill Sans MT ';
    font-size:45px;
    background: transparent  ; 
    padding: 20px;
  }

  .background{
    background-image: url(/public/Images/hero.jpg);
    background-attachment: relative;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    opacity: 100%;
    width: 2000px;
 
 
  }

  

 
  .icon-title{  
    text-align: center;
    position: relative;
    color: rgb(139, 0, 81);
    font-family:  'Gill Sans', 'Gill Sans MT';
    background: white;
    padding: 10px;
    font-size: 15pt;
  }

  .section-title{  
    text-align: center;
    position: relative;
    color: rgb(139, 0, 81);
    font-family:  'Gill Sans', 'Gill Sans MT';
    background: rgb(191, 235, 198);
    padding: 30px;
    font-size: 30pt;
  }

  .cartoon1-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    position: center;
    width: 50%;
    object-fit:cover; 
    height: 100%;
   
  }

  .about-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    position: center;
    width: 30%;
    object-fit:cover; 
   
   
  }

  .cartoon2-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    position: center;
    width: 20%;
    object-fit:cover; 
    height: 100%;
   
  }
  .cartoon3-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    position: center;
    width: 15%;
    object-fit:cover; 
    height: 100%;
   
  }

  .services-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    position: center;
    width: 15%;
    padding: 3em;
    height: 75%;
   
  }
 
 
  
  
  

  @media only screen and (max-width: 830px) {


    .text {
      font-size: 30px;
    }

    .hometext3 {
      font-size: 25px;
      padding: 20px;
    }

    .hometext1 {
      font-size: 20px;
      padding: 20px;
    }


    .section-title{  
     
      font-size: 15pt;
    }

    .copyrighttext {
     
      font-size: 7px;
      
    }

    .listtext {
      font-size: 7px;
    } 


    .informationtext2 {
      font-size: 15px; 
    } 

    .informationtext1 {
      font-size: 25px; 
    } 

    .footer-container {

     
      padding: 1rem 0 1.5rem 0;
     
    }

    .services-image {
      display: block;
      margin-left: auto;
      margin-right: auto;
      position: center;
      width: 15%;
      padding: .01em;
      height: 75%;
     
    }

   
  }
 

 